import {useState,useContext} from 'react';
import { Formik } from "formik"
import {UserContext} from '../context/UserContext';
import Spinner from "./modal/Spinner";
import LogoA from '../resourse/img/logoA.png'

const Login = () => {
    const {loginUser, wait, loggedInCheck} = useContext(UserContext);
    const [ShowSpinner, setShowSpinner] = useState(false)
    const [ErrMsg, setErrMsg] = useState(false);

    return (
        <>
            {ShowSpinner && <Spinner/>}
            <div className="flex h-screen w-screen justify-center items-center">
                <div className="w-full sm:max-w-sm flex flex-col justify-center items-center rounded-md ring-4 ring-sky-900 p-2 m-4 bg-white">
                    <div className="flex justify-center items-center">
                        <img src={LogoA} className="object-cover" alt="" />
                    </div>
                    <Formik
                        initialValues={{
                            email:'',
                            password:''
                        }}
                        validate={(valores) =>{
                            let errores={}
                            if(!valores.email){
                                errores.email='Por favor, ingrese un email'
                            }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(valores.email)){
                                errores.email='Expresión inválida'
                            }
                            if(!valores.password){
                                errores.password='Por favor, ingrese una contraseña'
                            }
                            return errores
                        }}
                        onSubmit= {async (valores,{resetForm}) =>{
                            setShowSpinner(true)

                            let email=valores.email;
                            let password=valores.password;

                            const data = await loginUser({
                                email,password
                            });
                            if(data.success){
                                resetForm()
                                await loggedInCheck();
                                return;
                            }
                            setShowSpinner(false)
                            setErrMsg(data.message.replace(/\\n/g, '\n'));
                            
                            setTimeout(() => {
                                setErrMsg('') 
                            }, 3000)
                        }}
                    >
                        {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
                            <form className="mt-2 w-full" onSubmit={handleSubmit}>
                                <div className="">
                                    {/* <label htmlFor="email" className="block text-md px-4 pt-2 font-extrabold">Email:</label> */}
                                    <input 
                                        type="email" 
                                        className="block w-full text-md px-4 py-2 bg-white border rounded-md focus:border-sky-900 focus:ring-sky-900 focus:outline-none focus:ring focus:ring-opacity-40"
                                        name="email" 
                                        id="email"
                                        placeholder="Email" 
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {touched.email && errors.email && <div className="text-sm font-semibold text-red-800 italic">{errors.email}</div>}
                                </div>
                                <div className="mt-2">
                                    {/* <label htmlFor="password" className="block text-md px-4 pt-2 font-extrabold">Contraseña:</label> */}
                                    <input 
                                        type="password"
                                        className="block text-md w-full px-4 py-2 bg-white border rounded-md focus:border-sky-900 focus:ring-sky-900 focus:outline-none focus:ring focus:ring-opacity-40"
                                        name="password" 
                                        id="password"
                                        placeholder="Contraseña"
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    {touched.password && errors.password && <div className="text-sm font-semibold text-red-800 italic">{errors.password}</div>}
                                </div>
                                <div className="m-2 flex justify-center items-center text-red-500">
                                    {ErrMsg && <p className="text-center whitespace-pre-line">{ErrMsg}</p>}
                                </div>
                                <div className="mt-4">
                                    {!wait && <button type="submit" className="w-full text-xl font-extrabold p-2 tracking-wide text-white transition-colors duration-200 transform bg-sky-900 rounded-md hover:bg-sky-700">Login</button>}
                                </div>
                                <div className="flex justify-center items-center mt-2">
                                    <p className="mt-6 text-xs font-semibold text-center">UV-20240315-1000</p>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        </>
    )
}

export default Login;