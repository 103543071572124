import { useContext, useState, useEffect, Suspense } from 'react'
import { UserContext } from '../../../context/UserContext'
import { BsFillTrashFill, BsFillPersonCheckFill, BsFillPeopleFill, BsFillPersonXFill, BsFillBackspaceFill,BsFillSendFill, BsFill0SquareFill, BsFill1SquareFill, BsFill2SquareFill, BsFill3SquareFill, BsFill4SquareFill, BsFill5SquareFill, BsFill6SquareFill, BsFill7SquareFill, BsFill8SquareFill, BsFill9SquareFill, BsFillCpuFill, BsFillQuestionCircleFill, BsFillCheckCircleFill, BsFillXCircleFill} from "react-icons/bs"
import {BiLoaderAlt} from "react-icons/bi"
import { Formik } from "formik"
import Spinner from "../../modal/Spinner";
import Nousuario from "../../modal/NoUsuario";
import { SeparateDateSelect } from "../../funciones/WorkDate"
import { Axios } from '../../../context/UserContext';

const ControlComensal = (props) => {
   const {user, asignacion, loggedInCheck} = useContext(UserContext)
   const [ShowSpinner, setShowSpinner] = useState(false)
   const [ShowMensaje, setShowMensaje] = useState({Show:false})
   const [ShowTeclado, setShowTeclado] = useState(false)
   const [ArrayCliente, setArrayCliente] = useState(asignacion.clientes)
   const [ArrayPersonal, setArrayPersonal] = useState({})
   const [ArraySucursal, setArraySucursal] = useState({})
   const [AsigSucursal, setAsigSucursal] = useState(asignacion.sucursales)
   const [ArrayServicio, setArrayServicio] = useState({})
   const [AsigServicio, setAsigServicio] = useState(asignacion.servicios)
   const [AsigGerencia, setAsigGerencia] = useState({})
   const [ArraySolicitudServicio, setArraySolicitudServicio] = useState({})
   const [ArrayRegistroServicio, setArrayRegistroServicio] = useState({})
   const [ArrayCedulaSolicitud, setArrayCedulaSolicitud] = useState({})
   const [ArrayCedulaRegistro, setArrayCedulaRegistro] = useState({})
   const [ArrayCedulaPersonal, setArrayCedulaPersonal] = useState({})
   const [OpenFuncion, setOpenFuncion] = useState("")
   const OpenDivFuncion = (value) =>{setOpenFuncion(OpenFuncion == value ? "" : value)}
   const [ArrayDCS, setArrayDCS] = useState({})
   const [Aviso, setAviso] = useState({Show:false})
   const [TableCoincidencia, setTableCoincidencia] = useState({Show:false})
   const [progress, setProgress] = useState(0);

   const CerrarAviso = (login) => {
      setAviso({Show:false})
   }
   const BuscarCoincidencia = (e)=>{
      const fecha = document.getElementById('fecha').value;
      const fechasel= SeparateDateSelect(fecha)
      const id_cliente = document.getElementById('cliente').value;
      const id_sucursal = document.getElementById('sucursal').value;
      const id_servicio = document.getElementById('servicio').value;
      const tipo_peticion = 1
      
      setArrayDCS({})
      if(fecha!="" && id_cliente!="" && id_sucursal!="" && id_servicio!=""){
         setShowSpinner(true)
         async function task1() {
            try{
               const {data} = await Axios.post('/solicitudcomensal.php',{
                  fechasel, id_cliente, id_sucursal, id_servicio, tipo_peticion, AsigGerencia
               });
               /* console.log("data-solicitud", data) */
               if(data.success){

                  const array_solicitud = data.datos;
                  const array_cedula_solicitud = [];
                  setArraySolicitudServicio(array_solicitud)
                  
                  data.datos.forEach(element => {
                     array_cedula_solicitud.push(element.nrocedula_personal)
                  });
                  let result_array_cedula_solicitud = array_cedula_solicitud.filter((item,index)=>{
                     return array_cedula_solicitud.indexOf(item) == index;
                  })
                  setArrayCedulaSolicitud(result_array_cedula_solicitud)
               }
            } catch(err){ console.log("err");console.log(err) }
         }
         async function task2() {
            try{
               const {data} = await Axios.post('/registrocomensal.php',{
                  fechasel, id_cliente, id_sucursal, id_servicio, tipo_peticion, AsigGerencia
               });
               /* console.log("data-registro", data) */
               if(data.success){
                  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

                  const array_registro = data.datos;
                  const array_cedula_registro = [];
                  data.datos.forEach(element => {
                     array_cedula_registro.push(element.nrocedula_personal)
                  });
                  setArrayRegistroServicio(array_registro)
                  
                  let result_array_cedula_registro = array_cedula_registro.filter((item,index)=>{
                     return array_cedula_registro.indexOf(item) == index;
                  })
                  setArrayCedulaRegistro(result_array_cedula_registro)
               }
            } catch(err){ console.log("err");console.log(err) }
         }
         async function main() {
            await Promise.all([task1(), task2()]);
            setShowSpinner(false)
         }
         main()
      }
   }
   const ChangeFecha = (e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
   }
   const ChangeCliente = async(e)=>{
      setArraySucursal({ Show:false,Datos:'' })
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
      const id_cliente = document.getElementById('cliente').value;
      if(id_cliente!=''){
         setShowSpinner(true)
         async function task1() {
            try{
               const {data} = await Axios.post('/sucursal_consultaporcliente.php',{
                  id_cliente, AsigSucursal
               });
               if(data.success==1){
                  setArraySucursal({
                     Show:true,
                     Datos:data.datos
                  })
               }
            } catch(err){ console.log("err");console.log(err) }
         }
         async function task2() {
            try{
               const {data} = await Axios.post('/personal_consultaporcliente.php',{
                  id_cliente, AsigGerencia
               });
               /* console.log("data-personal")
               console.log(data) */
               const array_cedula_personal = [];
               if(data.success==1){
                  data.datos.sort((a, b) => {
                     let comparison = a.nombres_personal.localeCompare(b.nombres_personal);
                     if (comparison == 0) {
                         return a.apellidos_personal.localeCompare(b.apellidos_personal);
                     }
                     return comparison;
                 });
                  setArrayPersonal(data.datos)
                  data.datos.forEach(element => {
                     array_cedula_personal.push(element.nrocedula_personal)
                  });
               }
               setArrayCedulaPersonal(array_cedula_personal)
               
            } catch(err){ console.log("err");console.log(err) }
         }
         async function main() {
            await Promise.all([task1(), task2()]);
            setShowSpinner(false)
         }
         main()
      }
   }   
   const ChangeSucursal = async(e)=>{
      setShowSpinner(true)
      setArrayServicio({ Show:false,Datos:'' })
      setTableCoincidencia({Show:false,})
      setShowMensaje({Show:false})
      const id_sucursal = document.getElementById('sucursal').value;

      try{
         const {data} = await Axios.post('/servicio_consultaporsucursal.php',{
            id_sucursal, AsigServicio
         });
         /* console.log("data-servicio")
         console.log(data) */
         if(data.success==1){
            setArrayServicio({
               Show:true,
               Datos:data.datos
            })
         }
      } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false);}
   }
   const ChangeServicio = async(e)=>{
      
      setTableCoincidencia({Show:false})
      setShowMensaje({Show:false})
      const fecha = document.getElementById('fecha').value;
      const id_cliente = document.getElementById('cliente').value;
      const id_sucursal = document.getElementById('sucursal').value;
      const id_servicio = document.getElementById('servicio').value;

      if(fecha!="" && id_servicio!=""){
         setShowSpinner(true)
         const [ySel, mSel, dSel]=fecha.split('-')
         const fechaSel=new Date(ySel,mSel-1,dSel)
         let HoInicio=""
         let HoFin=""
         try{
            const {data} = await Axios.post('/consultaGrupoFechaHora.php',{
               id_servicio
            });
            /* console.log("data- fecha/hora", data) */
            const [ySis, mSis, dSis]=data.fecha.split('-')
            const [hSis, iSis, sSis]=data.hora.split(':')
            const fechaSis=new Date(ySis,mSis-1,dSis)

            if(fechaSel.getTime() < fechaSis.getTime()){
               setAviso({
                  Show:true,
                  Logo:'BsFillXCircleFill',
                  Color:'red-500',
                  Texto:`El Registro de Comensal\n"NO" está disponible\n\nLa fecha es anterior a hoy`,
                  AlignTexto:true,
                  Botones:{
                     Bcerrar:true,
                  }
               })
            }else if(fechaSel.getTime() > fechaSis.getTime()){
               setAviso({
                  Show:true,
                  Logo:'BsFillXCircleFill',
                  Color:'red-500',
                  Texto:`El Registro de Comensal\n"NO" está disponible\n\nLa fecha es posterior a hoy`,
                  AlignTexto:true,
                  Botones:{
                     Bcerrar:true,
                  }
               })
            }else{

               ArrayServicio.Datos.some(element => {
                  if(element.id_servicio==id_servicio){
                      HoInicio=element.HoInicio
                      HoFin=element.HoFin
                      return true
                  }
               });
               const [hSelIn, iSelIn, sSelIn]=HoInicio.split(':')
               const [hSelFin, iSelFin, sSelFin]=HoFin.split(':')
               
               let fechaSis2=new Date(ySis,mSis-1,dSis,hSis,iSis)
               let fechaSelIn=new Date(ySel,mSel-1,dSel,hSelIn,iSelIn)
               let fechaSelFin=new Date(ySel,mSel-1,dSel,hSelFin,iSelFin)
               
               const hRIn = (fechaSelIn.getHours()<=9 ? '0'+fechaSelIn.getHours() :fechaSelIn.getHours())
               const iRIn = (fechaSelIn.getMinutes()<=9 ? '0'+fechaSelIn.getMinutes() :fechaSelIn.getMinutes())
               const hRFin = (fechaSelFin.getHours()<=9 ? '0'+fechaSelFin.getHours() :fechaSelFin.getHours())
               const iRFin = (fechaSelFin.getMinutes()<=9 ? '0'+fechaSelFin.getMinutes() :fechaSelFin.getMinutes())
               /* console.log("fechaSis2",fechaSis2)
               console.log("fechaSelIn", fechaSelIn)
               console.log("fechaSelFin", fechaSelFin) */
               if((fechaSis2.getTime() >= fechaSelIn.getTime()) && (fechaSis2.getTime()<= fechaSelFin.getTime())){
                  setShowTeclado(true)
                  let nombre_cliente = ''
                  let nombre_sucursal = ''
                  let nombre_servicio = ''
                  let HoInicio = ''
                  let HoFin = ''
                  ArrayCliente.some(element => {
                     if(element.id_cliente==id_cliente){
                        nombre_cliente=element.nombre_cliente
                        return true
                     }
                  });
                  ArraySucursal.Datos.some(element => {
                     if(element.id_sucursal==id_sucursal){
                        nombre_sucursal=element.nombre_sucursal
                        return true
                     }
                  });
                  ArrayServicio.Datos.some(element => {
                     if(element.id_servicio==id_servicio){
                        nombre_servicio=element.nombre_servicio
                        HoInicio=element.HoInicio
                        HoFin=element.HoFin
                        return true
                     }
                  });
                  
                  setTableCoincidencia({
                     Show:true,
                     fecha_solicitud:dSel+'-'+mSel+'-'+ySel,
                     fecha_sistema:dSis+'-'+mSis+'-'+ySis,
                     hora_sistema:hSis+':'+iSis+':'+sSis,
                     nombre_cliente: nombre_cliente,
                     nombre_sucursal: nombre_sucursal,
                     nombre_servicio: nombre_servicio,
                     HoInicio: HoInicio,
                     HoFin: HoFin,
                     id_cliente: id_cliente,
                     id_sucursal: id_sucursal,
                     id_servicio: id_servicio,
                  })
                  BuscarCoincidencia()
               }else if (fechaSis2.getTime() < fechaSelIn.getTime()){
                  setAviso({
                     Show:true,
                     Logo:'BsFillXCircleFill',
                     Color:'red-500',
                     Texto:`El Registro de Comensal\n"NO" está disponible\n\nComienza a las ${hRIn}:${iRIn}`,
                     AlignTexto:true,
                     Botones:{
                        Bcerrar:true,
                     }
                  })
               }else{
                  setAviso({
                     Show:true,
                     Logo:'BsFillXCircleFill',
                     Color:'red-500',
                     Texto:`El Registro de Comensal\n"NO" está disponible\n\nCulminó a las ${hRFin}:${iRFin}`,
                     AlignTexto:true,
                     Botones:{
                        Bcerrar:true,
                     }
                  })
               }
            }
         } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false); }
      }

   }
   const IngresarCedula=(e) =>{
      var texto=document.getElementById("div-numero").innerHTML
      if(texto.length <=7){
         texto=texto+e
         document.getElementById("div-numero").innerHTML=texto
      }
   }
   const BorrarUltimoCaracter=() =>{
      var texto=document.getElementById("div-numero").innerHTML
      if(texto.length >=1){
         texto = texto.substring(0, texto.length - 1);
         document.getElementById("div-numero").innerHTML=texto
      }
   }
   const LimpiarCaracter=() =>{
      var texto=document.getElementById("div-numero").innerHTML
      if(texto.length >=1){
         texto=""
         document.getElementById("div-numero").innerHTML=texto
      }
   }
   const VerificarCedula=()=>{

      var cedula=document.getElementById("div-numero").innerHTML
      
      
      if(cedula.length < 7){
         setAviso({
            Show:true,
            Logo:'BsFillPersonXFill',
            Color:'red-500',
            Texto:"El número\n\""+parseInt(cedula).toLocaleString('es-ES')+"\"\n\nes muy corto",
            SizeText:'text-3xl',
            AlignTexto:true,
            Botones:{
               Bcerrar:false,
               Benviar:false
            }
         })
         setTimeout(() => {
            CerrarAviso()
         }, 3000);
      }else{
         setAviso({
            Show:true,
            Logo:'BsFillQuestionCircleFill',
            Color:'orange-400',
            Texto:"Por favor confirme\n\nnúmero\n\""+parseInt(cedula).toLocaleString('es-ES')+"\"",
            SizeText:'text-3xl',
            AlignTexto:true,
            Botones:{
               Bcerrar:true,
               Benviar:false,
               BCargarCedula:true
            }
         })

      }
      
   }
   const CargarCedula=async()=>{
      CerrarAviso()
      setShowSpinner(true)
      let solicitado=0
      let duplicado=0
      let id_tipocomensal = null;

      const fecha = TableCoincidencia.fecha_solicitud
      const [dSel, mSel, ySel]=fecha.split('-') 
      const fechasel=ySel+''+mSel+''+dSel
      const id_cliente = parseInt(TableCoincidencia.id_cliente)
      const id_sucursal = parseInt(TableCoincidencia.id_sucursal)
      const id_servicio = parseInt(TableCoincidencia.id_servicio)
      const HoInicio = TableCoincidencia.HoInicio
      const HoFin = TableCoincidencia.HoFin
      const cedula=parseInt(document.getElementById("div-numero").innerHTML)
      const id_usuario = parseInt(user.id_usuario)
      const tipo_peticion = 2;
      /* console.log(HoInicio) */
      /* console.log(HoFin) */

      // Function to find id_tipocomensal based on cedula and array
      function findIdTipocomensal(array, cedula, keyCedula, keyId) {
         const foundElement = array.find(element => element[keyCedula] === cedula);
         return foundElement ? foundElement[keyId] : null;
      }

      if (ArrayCedulaSolicitud.includes(cedula)) {
         solicitado = 1;
         id_tipocomensal = findIdTipocomensal(ArraySolicitudServicio, cedula, 'nrocedula_solicitudcomensal', 'id_tipocomensal_solicitudcomensal');
      } else if (ArrayCedulaRegistro.includes(cedula)) {
         duplicado = 1;
         id_tipocomensal = findIdTipocomensal(ArrayRegistroServicio, cedula, 'nrocedula_registrocomensal', 'id_tipocomensal_registrocomensal');
      } else if (ArrayCedulaPersonal.includes(cedula)) {
         id_tipocomensal = findIdTipocomensal(ArrayPersonal, cedula, 'nrocedula_personal', 'id_tipocomensal');
      }

      try{
         const {data} = await Axios.post('/registrocomensal.php',{
            fechasel, cedula, id_tipocomensal, id_cliente, id_sucursal, id_servicio, id_usuario, tipo_peticion, solicitado, duplicado, AsigGerencia, HoInicio, HoFin
         });
         /* console.log("data-registrocomensal", data) */
         if(data.success==1){
            ArrayCedulaRegistro.push(cedula)
            ArrayRegistroServicio.push({
               nrocedula_registrocomensal: cedula,
               id_tipocomensal_registrocomensal: id_tipocomensal
            })
            let avisoConfig=[]
            if (solicitado == 1 && duplicado == 1) {
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillPersonXFill',
                  Color: 'orange-400',
                  Texto: `El número de cédula\n"${parseInt(cedula).toLocaleString('es-ES')}"\n\nRegistro DUPLICADO`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: false,
                        Benviar: false
                  }
               };
            } else if (solicitado == 1) {
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillPersonXFill',
                  Color: 'green-500',
                  Texto: `El número de cédula\n"${parseInt(cedula).toLocaleString('es-ES')}"\n\nRegistro EXITOSO`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: false,
                        Benviar: false
                  }
               };
            } else if (duplicado == 1) {
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillPersonXFill',
                  Color: 'orange-400',
                  Texto: `El número de cédula\n"${parseInt(cedula).toLocaleString('es-ES')}"\nNo tiene solicitud\n\nRegistro DUPLICADO`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: false,
                        Benviar: false
                  }
               };
            } else {
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillPersonXFill',
                  Color: 'orange-400',
                  Texto: `El número de cédula\n"${parseInt(cedula).toLocaleString('es-ES')}"\nNo tiene solicitud\n\nRegistro EXITOSO`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: false,
                        Benviar: false
                  }
               };
            }
            setAviso(avisoConfig);
            LimpiarCaracter()
         }else{
            let avisoConfig =[]
            if(data.sms){
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillXCircleFill',
                  Color: 'red-500',
                  Texto:`El Registro de Comensal\n"NO" está disponible\n\nCulminó a las ${HoFin}`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: true,
                        Benviar: false
                  }
               };
               setShowTeclado(false)
            }else{
               avisoConfig = {
                  Show: true,
                  Logo: 'BsFillXCircleFill',
                  Color: 'red-500',
                  Texto: `Error al registrar el número de cédula\n"${parseInt(cedula).toLocaleString('es-ES')}"`,
                  SizeText: 'text-3xl',
                  AlignTexto: true,
                  Botones: {
                        Bcerrar: false,
                        Benviar: false
                  }
               };
            }
            setAviso(avisoConfig);
         }
         setTimeout(() => {
            CerrarAviso()
         }, 3000);
      } catch(err){ console.log("err");console.log(err) }finally {setShowSpinner(false); }
   }
   
   return(
      <Suspense fallback={<Spinner />}>
         <>
            {ShowSpinner && <Spinner/>}
            {progress > 0  &&
               <>
                     <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t"></div>
                           {/*body*/}
                           <div className="w-full p-4 flex flex-col justify-center items-center text-white">
                                 <BiLoaderAlt className="animate-spin"/>
                                 <label htmlFor="" className="text-white mb-1">Cargando...</label>
                                 <div className="relative pt-1 w-full">
                                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white">
                                       <div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-sky-400"></div>
                                    </div>
                                 </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2"></div>
                        </div>
                     </div>
               </div>
               <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }
            {Aviso.Show &&
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative min-w-sm my-6 mx-auto max-w-sm">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none bg-sky-900">
                           {/*header*/}
                           <div className="flex justify-center items-center rounded-t">
                              {Aviso.Logo=="BsFillQuestionCircleFill" && <span className="p-2"><BsFillQuestionCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillCheckCircleFill" && <span className="p-2"><BsFillCheckCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}    
                              {Aviso.Logo=="BsFillXCircleFill" && <span className="p-2"><BsFillXCircleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPersonCheckFill" && <span className="p-2"><BsFillPersonCheckFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPersonXFill" && <span className="p-2"><BsFillPersonXFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                              {Aviso.Logo=="BsFillPeopleFill" && <span className="p-2"><BsFillPeopleFill className={`text-${Aviso.Color} text-5xl`}/></span>}
                           </div>
                           {/*body*/}
                           <div className="relative p-4 flex-auto text-white">
                              {Aviso.Texto &&
                                 <p className={`${(Aviso.AlignTexto ? "text-center" : "text-justify")} my-1 font-bold text-xl whitespace-pre-line`}>
                                    {Aviso.Texto}
                                 </p>
                              }
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-center rounded-b px-2">
                              <div className="flex items-center justify-between">
                                 {Aviso.Botones.Bcerrar && <button className="text-red-500 hover:bg-red-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" onClick={()=>CerrarAviso(Aviso.Login)}>Cerrar</button>}
                                 {Aviso.Botones.Benviar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                                 {Aviso.Botones.Beliminar && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 text-xs  outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">Si</button>}
                                 {Aviso.Botones.BCargarCedula && <button className="text-green-500 hover:bg-green-500 hover:text-white rounded-lg background-transparent font-bold uppercase px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border-2 text-xl" type="button" onClick={()=>CargarCedula()}>Si</button>}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="opacity-50 fixed inset-0 z-40 bg-sky-900"></div>
               </>
            }

            {user.status_usuario ?
               <>
                  {/* ############################### BODY ################################### */}
                  <div className="flex flex-row items-center bg-sky-900 py-2 px-4 text-xl text-white font-bold">
                     <span className="font-bold text-lg"><BsFillCpuFill/></span>
                     <span className="ml-2 text-2xl text-white">Control Comensal</span>
                  </div>
                  <div className="flex flex-col justify-center items-center h-full ease-in duration-300">
                     {!ShowTeclado &&
                        <Formik
                           initialValues={{
                              fecha:'',
                              cliente:'',
                              sucursal:'',
                              servicio:'',
                              buscar:'',
                              cedula:[]
                           }}
                           validate={(valores) =>{
                              
                              let errores={}
                              if(!valores.fecha){
                                 errores.fecha='Por favor, seleccione una fecha'
                              }
                              if(!valores.cliente){
                                 errores.cliente='Por favor, seleccione un item'
                              }
                              if(!valores.sucursal){
                                 errores.sucursal='Por favor, seleccione un item'
                              }
                              if(!valores.servicio){
                                 errores.servicio='Por favor, seleccione un item'
                              }
                              return errores
                           }}
                           onSubmit = {async (valores,{resetForm}) => {
                              
                           }}
                        >
                           {({values, errors, touched, handleSubmit, handleChange, handleBlur}) =>(
                              <form onSubmit={handleSubmit}>
                                 <div className="flex flex-col justify-between items-center m-4">
                                    <div className='w-auto'>
                                       <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                          <label htmlFor="fecha" className="font-bold text-white mb-2">Fecha</label>
                                          <input 
                                                type="date" 
                                                id="fecha" 
                                                name="fecha"
                                                value={values.fecha}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none"
                                                onChange={event=>{ handleChange(event); ChangeFecha(event); }}
                                                onClick={(event) => {
                                                   values.cliente=""
                                                   values.sucursal=""
                                                   values.servicio=""
                                                }}
                                                onBlur={event =>{handleBlur(event); }}
                                          />
                                          {touched.fecha && errors.fecha && <div className="text-sm font-semibold text-red-500 italic">{errors.fecha}</div>}
                                       </div>
                                       <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                             <label htmlFor="cliente" className="font-bold text-white mb-2">Cliente</label>
                                             <select 
                                                id="cliente" 
                                                name="cliente"
                                                value={values.cliente}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                                onChange={event=>{ handleChange(event); ChangeCliente(event); }}
                                                onClick={(event) => {
                                                   values.sucursal=""
                                                   values.servicio=""
                                                }}
                                                onBlur={handleBlur}
                                             >
                                                <option value="">---Selección---</option>
                                                {ArrayCliente.length>0 && (
                                                   ArrayCliente.map(function(d,i) {
                                                         return (
                                                            <option key={"cliente_"+i} value={d.id_cliente}>{d.nombre_cliente}</option>
                                                         )
                                                   })
                                                )}
                                             </select>
                                             {touched.cliente && errors.cliente && <div className="text-sm font-semibold text-red-500 italic">{errors.cliente}</div>}
                                       </div>
                                       <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                          <label htmlFor="sucursal" className="font-bold text-white mb-2">Sucursal</label>
                                          <select 
                                                id="sucursal" 
                                                name="sucursal"
                                                value={values.sucursal}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                                onChange={event=>{ handleChange(event); ChangeSucursal(event); }}
                                                onClick={(event) => {
                                                   values.servicio=""
                                                }}
                                                onBlur={handleBlur}
                                          >
                                                <option value="">---Selección---</option>
                                                {ArraySucursal.Show && (
                                                   ArraySucursal.Datos.map(function(d,i) {
                                                      return (
                                                         <option key={"sucursal_"+i} value={d.id_sucursal}>{d.nombre_sucursal}</option>
                                                      )
                                                   })
                                                )}
                                          </select>
                                          {touched.sucursal && errors.sucursal && <div className="text-sm font-semibold text-red-500 italic">{errors.sucursal}</div>}
                                       </div>
                                       <div className="m-1 p-4 bg-sky-900 flex flex-col justify-center items-center rounded-md">
                                          <label htmlFor="servicio" className="font-bold text-white mb-2">Servicio</label>
                                          <select 
                                                id="servicio" 
                                                name="servicio"
                                                value={values.servicio}
                                                onChange={event=>{ handleChange(event); ChangeServicio(event);}}
                                                onBlur={handleBlur}
                                                className="form-select block w-full px-3 py-1.5 text-base font-normal text-black bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out mb-1 focus:text-black focus:bg-white focus:border-sky-600 focus:outline-none" 
                                          >
                                                <option value="">---Selección---</option>
                                                {ArrayServicio.Show && (
                                                   ArrayServicio.Datos.map(function(d,i) {
                                                      return (
                                                         <option key={"servicio_"+i} value={d.id_servicio}>{d.nombre_servicio}</option>
                                                      )
                                                   })
                                                )}
                                          </select>
                                          {touched.servicio && errors.servicio && <div className="text-sm font-semibold text-red-500 italic">{errors.servicio}</div>}
                                       </div>
                                    </div>
                                 </div>
                              </form>
                           )}
                        </Formik>
                     }
                     {ShowTeclado &&
                        <>
                           <div className="flex flex-col justify-between h-full w-full md:w-7/10 lg:w-1/2 xl:w-auto mb-8">
                              <div className="flex flex-row justify-between items-center bg-sky-900 mb-2 px-4 py-1 text-white text-xs">
                                 <div>
                                    <div>{TableCoincidencia.fecha_solicitud}</div>
                                    <div>{TableCoincidencia.nombre_cliente}</div>
                                    <div>{TableCoincidencia.nombre_sucursal}</div>
                                    <div>{TableCoincidencia.nombre_servicio}</div>
                                 </div>
                                 <div className="flex justify-center items-center p-1"><button type="button" className="text-3xl text-red-900" onClick={()=>setShowTeclado(false)}><BsFillXCircleFill/></button></div>
                              </div>
                              <div className="flex flex-col justify-between border-4 border-solid h-3/4  bg-black">
                                 <div id="div-numero" className="flex flex-row justify-center items-center w-100 rounded-lg text-center font-bold text-4xl h-1/6 m-2 bg-white"></div>
                                 <div className="flex flex-col justify-between h-5/6">
                                    <div className="flex flex-row justify-between p-1 h-1/4">
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("1"); }}><BsFill1SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("2"); }}><BsFill2SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("3"); }}><BsFill3SquareFill/></button>
                                    </div>
                                    <div className="flex flex-row justify-between p-1 h-1/4">
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("4"); }}><BsFill4SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("5"); }}><BsFill5SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("6"); }}><BsFill6SquareFill/></button>
                                    </div>
                                    <div className="flex flex-row justify-between p-1 h-1/4">
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("7"); }}><BsFill7SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("8"); }}><BsFill8SquareFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("9"); }}><BsFill9SquareFill/></button>
                                    </div>
                                    <div className="flex flex-row justify-between p-1 h-1/4">
                                       <button className="w-1/3 bg-orange-400 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ BorrarUltimoCaracter(); }}><BsFillBackspaceFill/></button>
                                       <button className="w-1/3 bg-sky-900 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 text-center flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ IngresarCedula("0"); }}><BsFill0SquareFill/></button>
                                       <button className="w-1/3 bg-green-600 text-white font-bold text-4xl p-1.5 mx-1 lg:mx-2 flex justify-center items-center border-2 border-solid rounded-lg border-white active:scale-95" onClick={()=>{ VerificarCedula(); }}><BsFillSendFill/></button>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </>
                     }
                  </div>
                  {/* ############################### FIN BODY ################################### */}
               </>
            :
               <Nousuario user={user.email_usuario}/>
            }
         </>
      </Suspense>
   )
}

export default ControlComensal