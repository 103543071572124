import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {useContext} from 'react'
import {UserContext} from './context/UserContext';

import Login from './components/Login';
import Home from './components/Home';

import {PrivateRoute} from './components/privateRoute';
import {RedirectRoute} from './components/redirectRoute';

import Personal from './components/funciones/recursoshumanos/Personal';
import Error404 from './components/modal/Error404';

function App() {

  const {user} = useContext(UserContext);
  /* console.log("user", user) */

  return (
    
    <div className="container">
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<RedirectRoute><Login/></RedirectRoute>} />
            <Route exact path='/home' element = {<PrivateRoute pat="home"> <Home /> </PrivateRoute>}/>
            <Route exact path='/personal' element = {<PrivateRoute pat="personal"> <Personal /> </PrivateRoute>}/>
            {/* <Route path="*" element={<Navigate to={user ? '/':'/login'} />} /> */}
            <Route path="*" caseSensitive={true} element={<Error404/>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
