import { useContext, useState } from 'react'
import { UserContext } from '../../context/UserContext'
import { BsFillCpuFill, BsWindowFullscreen, BsFillArrowUpSquareFill, BsFillArrowDownSquareFill } from "react-icons/bs"

import RelacionSolicitudComidaInterna from "../funciones/operaciones/RelacionSolicitudComidaInterna"
import RelacionServicioComida from "../funciones/operaciones/RelacionServicioComida"

const Operaciones = (props) => {
   const {user} = useContext(UserContext)
   const [LocacionHttp, setLocacionHttp] = useState(window.location.href.split("/"));
   const [OpenedWindows, setOpenedWindows] = useState([]);

   const openNewWindow = (url) => {
      let link=LocacionHttp[0]+"//"+LocacionHttp[2]+url
      const newWindow = window.open(link, "_blank");
      setOpenedWindows(prevWindows => [...prevWindows, newWindow]);
   };

   const CerrarVentana =(url)=>{
      OpenedWindows.forEach(element => {
         if(element.location.pathname==url){
            element.close()
         }
      });
   }

   const [OpenFuncion, setOpenFuncion] = useState("")
   const OpenDivFuncion = (value) =>{
      setOpenFuncion(OpenFuncion == value ? "" : value)
   }
      
   return (
      <>
         
         <div className="flex flex-row items-center bg-sky-900 py-2 px-4 text-xl text-white font-bold">
            <span className="font-bold text-lg"><BsFillCpuFill/></span>
            <span className="ml-2 text-2xl text-white">Operaciones</span>
         </div>

         <div className="mt-1 px-2 shadow rounded-lg mb-10">
            
            <fieldset className="border-double border-4 border-sky-900 rounded-lg p-3 mb-2">
               {(user.V_T || user.F_5) ?
                  <>
                     <legend className="py-2 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white hover:bg-sky-700">
                        <a className='cursor-pointer' onClick={()=>{ OpenDivFuncion("Operaciones_relacionsolicitudcomidainterna"); CerrarVentana("/relacionsolicitudcomidainterna")}}><div className="flex flex-row items-center"><span className="mr-1">Relación Solicitud Comida (Interna)</span><span className="font-bold text-lg">{OpenFuncion=="Operaciones_relacionsolicitudcomidainterna" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></a>
                     </legend>

                     {OpenFuncion=="Operaciones_relacionsolicitudcomidainterna" ?
                        <RelacionSolicitudComidaInterna/>
                     : 
                        <div className="relative">
                           <a className="absolute top-0 right-0 inline-block px-1 py-1 bg-white text-sky-900 font-medium text-lg leading-tight uppercase rounded-lg shadow-md hover:bg-sky-700 hover:text-white focus:bg-sky-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-sky-800 active:shadow-lg transition duration-150 ease-in-out cursor-pointer" onClick={()=>{openNewWindow("/relacionsolicitudcomidainterna")}}><BsWindowFullscreen/></a>
                        </div>
                     }
                  </>
               :
                  <legend className="py-2 px-2 bg-gray-400 rounded-lg text-lg font-bold text-white">
                     <a className='cursor-not-allowed'><span className="mr-1">Relación Solicitud Comida (Interna)</span></a>
                  </legend>
               }
            </fieldset>

            <fieldset className="border-double border-4 border-sky-900 rounded-lg p-3 mb-2">
               {(user.V_T || user.F_6) ?
                  <>
                     <legend className="py-2 px-2 bg-sky-900 rounded-lg text-lg font-bold text-white hover:bg-sky-700">
                        <a className='cursor-pointer' onClick={()=>{ OpenDivFuncion("Operaciones_relacionserviciocomida"); CerrarVentana("/relacionserviciocomida")}}><div className="flex flex-row items-center"><span className="mr-1">Relación Servicio (Comida)</span><span className="font-bold text-lg">{OpenFuncion=="Operaciones_relacionserviciocomida" ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill/>}</span></div></a>
                     </legend>

                     {OpenFuncion=="Operaciones_relacionserviciocomida" ?
                        <RelacionServicioComida/>
                     : 
                        <div className="relative">
                           <a className="absolute top-0 right-0 inline-block px-1 py-1 bg-white text-sky-900 font-medium text-lg leading-tight uppercase rounded-lg shadow-md hover:bg-sky-700 hover:text-white focus:bg-sky-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-sky-800 active:shadow-lg transition duration-150 ease-in-out cursor-pointer" onClick={()=>{openNewWindow("/relacionserviciocomida")}}><BsWindowFullscreen/></a>
                        </div>
                     }
                  </>
               :
                  <legend className="py-2 px-2 bg-gray-400 rounded-lg text-lg font-bold text-white">
                     <a className='cursor-not-allowed'><span className="mr-1">Relación Servicio (Comida)</span></a>
                  </legend>
               }
            </fieldset>
            

         </div>

      </>
   )
}

export default Operaciones